/* Base styles */
html {
  scroll-behavior: smooth;
}

.home-container {
  width: 100%;
  background: linear-gradient(135deg, #0a192f, #112240, #1a365d);
  color: #e6f1ff;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Hero Section */
.hero {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem 6rem 1rem;
  width: 100%;
  margin-bottom: 4rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(10, 25, 47, 0.5) 0%, rgba(10, 25, 47, 0.8) 100%);
}

.hero-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 5rem auto;
  padding: 0 2rem;
  gap: 2rem;
  z-index: 2;
}

.hero-text {
  flex: 1 1 50%;
  max-width: 600px;
  text-align: left;
  padding-right: 1rem;
}

.hero-visual {
  flex: 1 1 40%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.hero-game-container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.greeting {
  display: block;
  font-size: 1.5rem;
  color: #64ffda;
  margin-bottom: 1rem;
}

.name {
  display: block;
  font-size: clamp(40px, 8vw, 70px);
  font-weight: 800;
  background: linear-gradient(90deg, #64ffda, #00bcd4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0.5rem 0;
  line-height: 1.1;
}

.subtitle {
  font-size: 1.5rem;
  color: #8892b0;
  margin: 1rem 0;
  opacity: 0.8;
  animation: fadeIn 2s ease-out;
}

.role-tags {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1.5rem 0;
}

.role-tags span {
  padding: 0.5rem 1rem;
  background: rgba(100, 255, 218, 0.1);
  border: 1px solid #64ffda;
  border-radius: 4px;
  color: #64ffda;
  font-size: 0.9rem;
}

.cta-buttons {
  margin-top: 2rem;
  display: flex;
  gap: 1.5rem;
}

.cta-button {
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background: #64ffda;
  color: #0a192f;
}

.cta-button.secondary {
  border: 1px solid #64ffda;
  color: #64ffda;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  opacity: 0.7;
  color: #64ffda;
  z-index: 2;
}

.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid #64ffda;
  border-radius: 15px;
  position: relative;
}

.mouse::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background: #64ffda;
  left: 50%;
  top: 8px;
  transform: translateX(-50%);
  border-radius: 50%;
  animation: scroll 2s infinite;
}

/* About Section */
.about-section {
  padding: 100px 1rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
}

.about-content {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 40px;
  align-items: start;
}

.about-text {
  color: #8892b0;
  line-height: 1.8;
}

.about-text p {
  margin-bottom: 1.5rem;
}

.skills-container {
  background: rgba(100, 255, 218, 0.05);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(100, 255, 218, 0.1);
}

.skills-container h3 {
  color: #64ffda;
  margin-bottom: 1rem;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.tech-item {
  background: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.tech-item:hover {
  background: rgba(100, 255, 218, 0.2);
  transform: translateY(-3px);
}

/* Latest Post Section */
.latest-post-section {
  padding: 100px 1rem;
  width: 100%;
  background: rgba(10, 25, 47, 0.5);
}

.latest-post-section .section-title {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto 2rem auto;
}

.latest-post-card {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  transition: transform 0.3s ease;
  border: 1px solid rgba(100, 255, 218, 0.1);
}

.latest-post-image {
  width: 300px;
  height: 250px;
  object-fit: cover;
}

.post-content {
  padding: 2rem;
  flex: 1;
}

.post-content h3 {
  color: #e6f1ff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.latest-post-excerpt {
  color: #8892b0;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.read-more-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #64ffda;
  color: #0a192f;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-weight: 600;
}

.read-more-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Projects Section */
.projects-section {
  padding: 100px 1rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.02);
}

.projects-section .section-title {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto 2rem auto;
}

.projects-grid {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 3rem;
}

.project-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(100, 255, 218, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-color: #64ffda;
}

.project-card h3 {
  color: #e6f1ff;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.project-card p {
  color: #8892b0;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  flex-grow: 1;
}

.project-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: transparent;
  color: #64ffda;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid #64ffda;
  transition: all 0.3s ease;
  text-align: center;
}

.project-link:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
}

/* Footer */
.footer {
  width: 100%;
  background: rgba(10, 25, 47, 0.8);
  padding: 40px 0;
  text-align: center;
}

/* Section Titles */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #e6f1ff;
  position: relative;
  padding-bottom: 1rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: #64ffda;
  border-radius: 2px;
}

/* Social Links */
.social-links {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px 0;
}

.social-links a {
  color: #64ffda;
  font-size: 3rem;
  transition: all 0.3s ease;
  opacity: 0.9;
}

.social-links a:hover {
  color: #fff;
  transform: translateY(-3px);
  opacity: 1;
}

/* Loading State */
.loading {
  text-align: center;
  padding: 2rem;
  color: #8892b0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scroll {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-content {
    gap: 2rem;
  }

  .name {
    font-size: 3.5rem;
  }
}

@media (max-width: 960px) {
  .hero {
    padding-bottom: 5rem;
    margin-bottom: 3rem;
  }

  .hero-content {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
    margin-bottom: 3rem;
  }

  .hero-text {
    flex-basis: auto;
    max-width: 600px;
    order: 1;
    padding-right: 0;
    margin-bottom: 1rem;
    text-align: center;
  }

  .hero-visual {
    flex-basis: auto;
    width: 80%;
    max-width: 400px;
    min-height: 250px;
    order: 0;
  }

  .hero-game-container {
    margin-top: 0;
    max-width: 95%;
  }

  .role-tags {
    justify-content: center;
  }

  .cta-buttons {
    justify-content: center;
  }

  #about,
  #latest-post,
  #projects {
    padding: 80px 1rem;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 3rem 1rem 4rem 1rem;
    margin-bottom: 2rem;
  }

  .hero-content {
    margin-bottom: 2rem;
  }

  .hero-visual {
    min-height: 200px;
    max-width: 300px;
  }

  .hero-game-container {
    padding: 0 0.5rem;
  }

  #about,
  #latest-post,
  #projects {
    padding: 60px 1rem;
  }

  .name {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .about-content {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .latest-post-card {
    flex-direction: column;
  }

  .latest-post-image {
    width: 100%;
    height: 200px;
  }

  .scroll-indicator {
    display: none;
  }

  .social-links {
    gap: 30px;
  }

  .social-links a {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .greeting {
    font-size: 1.2rem;
  }

  .name {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .cta-button {
    width: 100%;
    text-align: center;
  }

  .social-links a {
    font-size: 2rem;
  }
}