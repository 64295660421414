.games-page {
   padding: 20px;
   max-width: 1200px;
   margin: 0 auto;
}

.games-section {
   margin-bottom: 40px;
}

.games-section h2 {
   color: #1f2937;
   margin-bottom: 20px;
   font-size: 1.8rem;
}

.games-grid {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
   gap: 24px;
   margin-top: 20px;
}

.game-card {
   background: #ffffff;
   border-radius: 12px;
   overflow: hidden;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
   transition: transform 0.2s, box-shadow 0.2s;
   cursor: pointer;
   text-decoration: none;
   color: inherit;
   display: flex;
   flex-direction: column;
}

.game-card:hover {
   transform: translateY(-4px);
   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.game-card-image-container {
   width: 100%;
   height: 180px;
   overflow: hidden;
}

.game-card-image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   transition: transform 0.3s ease;
}

.game-card:hover .game-card-image {
   transform: scale(1.05);
}

.game-card-content {
   padding: 16px;
}

.game-card-content h3 {
   color: #1f2937;
   font-size: 1.25rem;
   margin-bottom: 8px;
}

.game-card-content p {
   color: #6b7280;
   font-size: 0.95rem;
   line-height: 1.5;
}

/* Estilos para las salas */
.room-card {
   background: #fff;
   border-radius: 12px;
   padding: 24px;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
   transition: box-shadow 0.3s ease;
}

.room-card:hover {
   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.room-card h3 {
   color: #1f2937;
   font-size: 1.4rem;
   margin-bottom: 8px;
}

.room-card p {
   color: #6b7280;
   margin-bottom: 16px;
}

.room-status {
   margin: 20px 0;
   padding: 16px;
   background: #f9fafb;
   border-radius: 8px;
}

.game-status {
   margin-bottom: 16px;
}

.status-indicator {
   padding: 6px 12px;
   border-radius: 6px;
   font-size: 0.9em;
   font-weight: 500;
   display: inline-block;
}

.status-indicator.in-progress {
   background-color: #fef3c7;
   color: #92400e;
}

.status-indicator.waiting {
   background-color: #e0e7ff;
   color: #3730a3;
}

.players-info {
   margin-top: 16px;
}

.player-count {
   display: block;
   margin-bottom: 12px;
   color: #6b7280;
   font-size: 0.95rem;
}

.players-list {
   display: flex;
   gap: 24px;
   margin-top: 12px;
}

.team {
   flex: 1;
   background: #ffffff;
   padding: 12px;
   border-radius: 8px;
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.team h4 {
   margin: 0 0 12px 0;
   font-size: 1rem;
   color: #374151;
   text-align: center;
}

.player-name {
   display: block;
   padding: 6px 10px;
   margin: 6px 0;
   border-radius: 6px;
   font-size: 0.9em;
   text-align: center;
   transition: transform 0.2s ease;
}

.player-name:hover {
   transform: translateX(4px);
}

.player-name.mammals {
   background-color: #dbeafe;
   color: #1e40af;
}

.player-name.reptiles {
   background-color: #fee2e2;
   color: #991b1b;
}

.join-button {
   width: 100%;
   padding: 12px;
   background-color: #3b82f6;
   color: white;
   border: none;
   border-radius: 8px;
   cursor: pointer;
   font-size: 1rem;
   font-weight: 500;
   transition: all 0.2s ease;
   margin-top: 16px;
}

.join-button:hover {
   background-color: #2563eb;
   transform: translateY(-2px);
}

.join-button:active {
   transform: translateY(0);
}

.back-button {
   padding: 8px 16px;
   background-color: #e5e7eb;
   color: #374151;
   border: none;
   border-radius: 6px;
   cursor: pointer;
   font-size: 0.95rem;
   margin-bottom: 24px;
   transition: all 0.2s ease;
}

.back-button:hover {
   background-color: #d1d5db;
}

/* Responsive adjustments */
@media (max-width: 768px) {
   .games-grid {
       grid-template-columns: 1fr;
   }

   .players-list {
       flex-direction: column;
       gap: 16px;
   }

   .room-card {
       padding: 16px;
   }
}

/* Estilos mejorados para la sección de salas */
.rooms-grid {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
   gap: 30px;
   margin-top: 30px;
}

.room-card {
   background: #ffffff;
   border-radius: 16px;
   padding: 24px;
   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
   transition: all 0.3s ease;
   position: relative;
   overflow: hidden;
   border: 1px solid #e5e7eb;
}

.room-card::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 4px;
   background: linear-gradient(90deg, #3b82f6, #ef4444);
}

.room-card h3 {
   color: #1f2937;
   font-size: 1.5rem;
   margin-bottom: 12px;
   font-weight: 600;
}

.room-card p {
   color: #6b7280;
   margin-bottom: 20px;
   font-size: 0.95rem;
   line-height: 1.5;
}

.room-status {
   background: #f8fafc;
   margin: 20px -24px;
   padding: 20px 24px;
   border-top: 1px solid #e5e7eb;
   border-bottom: 1px solid #e5e7eb;
}

.game-status {
   margin-bottom: 20px;
   text-align: center;
}

.status-indicator {
   padding: 8px 16px;
   border-radius: 30px;
   font-size: 0.9em;
   font-weight: 500;
   display: inline-block;
}

.status-indicator.in-progress {
   background: linear-gradient(45deg, #fef3c7, #fde68a);
   color: #92400e;
   box-shadow: 0 2px 8px rgba(146, 64, 14, 0.1);
}

.status-indicator.waiting {
   background: linear-gradient(45deg, #e0e7ff, #c7d2fe);
   color: #3730a3;
   box-shadow: 0 2px 8px rgba(55, 48, 163, 0.1);
}

.players-info {
   margin-top: 20px;
}

.player-count {
   text-align: center;
   display: block;
   margin-bottom: 16px;
   color: #4b5563;
   font-size: 1rem;
   font-weight: 500;
}

.players-list {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 20px;
   margin-top: 16px;
}

.team {
   background: #ffffff;
   padding: 16px;
   border-radius: 12px;
   box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.team h4 {
   margin: 0 0 12px 0;
   font-size: 1.1rem;
   color: #374151;
   text-align: center;
   font-weight: 600;
   padding-bottom: 8px;
   border-bottom: 2px solid #e5e7eb;
}

.player-name {
   display: flex;
   align-items: center;
   padding: 8px 12px;
   margin: 8px 0;
   border-radius: 8px;
   font-size: 0.95em;
   transition: all 0.2s ease;
}

.player-name::before {
   content: '•';
   margin-right: 8px;
   font-size: 1.2em;
}

.player-name.mammals {
   background: linear-gradient(45deg, #dbeafe, #bfdbfe);
   color: #1e40af;
}

.player-name.reptiles {
   background: linear-gradient(45deg, #fee2e2, #fecaca);
   color: #991b1b;
}

.join-button {
   width: 100%;
   padding: 14px;
   margin-top: 24px;
   background: linear-gradient(45deg, #3b82f6, #2563eb);
   color: white;
   border: none;
   border-radius: 12px;
   cursor: pointer;
   font-size: 1.1rem;
   font-weight: 600;
   transition: all 0.3s ease;
   text-transform: uppercase;
   letter-spacing: 0.5px;
   box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.join-button:hover {
   transform: translateY(-2px);
   box-shadow: 0 6px 16px rgba(37, 99, 235, 0.3);
}

.join-button:active {
   transform: translateY(0);
   box-shadow: 0 2px 8px rgba(37, 99, 235, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
   .rooms-grid {
       grid-template-columns: 1fr;
       padding: 0 16px;
   }

   .room-card {
       padding: 20px;
   }

   .players-list {
       grid-template-columns: 1fr;
   }

   .status-indicator {
       font-size: 0.85em;
   }
}
