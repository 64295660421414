/* App.css */
.main-content {
  padding-top: 80px; /* Solo mantenemos el padding superior para el header */
  width: 100%;
  overflow-x: hidden;
}

/* Ajustes para páginas específicas */
.blog-container,
.about-container,
.games-page {
  padding-top: 20px;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}
