.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 15px;
}

.logo-container {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: all 0.3s ease;
}

.circular-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-welcome-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  padding-right: 20px;
}

nav {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1a1a1a;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  transform: scaleX(1);
}

.nav-link:hover,
.nav-link:focus {
  background-color: rgba(26, 26, 26, 0.1);
}

.nav-link.active {
  font-weight: bold;
}

.welcome-message {
  font-size: 0.9rem;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

@media (max-width: 768px) {
  .logo-link {
    margin-left: 10px;  /* Margen más pequeño en móvil */
  }

  header {
    height: auto;
    padding: 10px;
  }

  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-welcome-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
  }

  nav {
    flex-wrap: wrap;
  }

  .welcome-message {
    margin-top: 5px;
  }
}
