.blog-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: #fff;
  }
  
  .blog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }
  
  .blog-header h1 {
    font-size: 2.5rem;
    color: #1a1a1a;
    margin: 0;
  }
  
  .new-post-button {
    padding: 0.75rem 1.5rem;
    background: #0066cc;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    transition: background 0.3s ease;
  }
  
  .new-post-button:hover {
    background: #0052a3;
  }
  
  .blog-posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .post-card {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background: white;
  }
  
  .post-card:hover {
    transform: translateY(-5px);
  }
  
  .post-image {
    position: relative;
    width: 100%;
    height: 250px; /* Aumentamos un poco la altura */
    overflow: hidden;
    background: #f5f5f5; /* Color de fondo por si la imagen no carga */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .post-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Mantiene la proporción y cubre todo el espacio */
    object-position: center; /* Centra la imagen */
  }
  
  .post-card:hover .post-image img {
    transform: scale(1.05);
  }
  
  .post-content {
    padding: 1.5rem;
  }
  
  .post-content h2 {
    font-size: 1.25rem;
    margin: 0 0 1rem;
    color: #1a1a1a;
  }
  
  .post-meta {
    display: flex;
    gap: 1rem;
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .post-excerpt {
    color: #444;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .post-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .read-more {
    color: #0066cc;
    text-decoration: none;
    font-weight: 500;
  }
  
  .admin-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .admin-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .admin-actions button:first-child {
    background: #4CAF50;
    color: white;
  }
  
  .admin-actions button:last-child {
    background: #f44336;
    color: white;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 2rem;
  }
  
  .page-button {
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    background: white;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .page-button.active {
    background: #0066cc;
    color: white;
    border-color: #0066cc;
  }
  
  @media (max-width: 768px) {
    .blog-posts-grid {
      grid-template-columns: 1fr;
    }
    
    .blog-header {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }