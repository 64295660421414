/* Estilos básicos del blog */
.blog-post {
    max-width: 100%;
    word-wrap: break-word;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #2d3748;
}

/* Estilos para títulos */
.blog-title {
    font-size: 2.5em;
    font-weight: 800;
    color: #1a202c;
    margin-bottom: 0.5em;
    line-height: 1.2;
}

.blog-subtitle {
    font-size: 1.8em;
    font-weight: 700;
    color: #2d3748;
    margin: 1.5em 0 0.5em;
}

.blog-section {
    font-size: 1.4em;
    font-weight: 600;
    color: #4a5568;
    margin: 1.2em 0 0.4em;
}

/* Bloques especiales */
.blog-quote {
    border-left: 4px solid #4a5568;
    padding: 1em 2em;
    margin: 1.5em 0;
    background: #f7fafc;
    font-style: italic;
}

.blog-note {
    background: #ebf8ff;
    border: 1px solid #90cdf4;
    border-radius: 8px;
    padding: 1em;
    margin: 1em 0;
}

.blog-alert {
    background: #fff5f5;
    border: 1px solid #feb2b2;
    border-radius: 8px;
    padding: 1em;
    margin: 1em 0;
    color: #c53030;
}

/* Elementos inline */
.highlight {
    background: #fef3c7;
    padding: 0.2em 0.4em;
    border-radius: 4px;
}

.underline-text {
    border-bottom: 2px solid #4a5568;
}

/* Meta información */
.blog-meta {
    color: #718096;
    font-size: 0.9em;
    margin-bottom: 2em;
}

/* Imágenes */
.blog-post img {
    max-width: 100%;
    height: auto;
}

.featured-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1em 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Review específicos */
.game-meta {
    background: #f7fafc;
    padding: 1em;
    border-radius: 8px;
    margin: 1em 0;
}

/* Tutorial específicos */
.tutorial-meta {
    background: #f0fff4;
    padding: 1em;
    border-radius: 8px;
    margin: 1em 0;
}

/* Contenedores */
.content-part {
    margin-bottom: 1rem;
}

.game-container {
    max-width: 100%;
    overflow-x: auto;
    margin: 1.5em 0;
}

/* Contenedores multimedia */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 1em 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.audio-container {
    margin: 1em 0;
    padding: 1em;
    background: #f7fafc;
    border-radius: 8px;
}

.audio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;
}

.audio-title {
    font-weight: bold;
    color: #2d3748;
}

/* Estilos del bloque de código */
.code-block {
    position: relative;
    margin: 1.5em 0;
    background: #282c34;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background: #21252b;
    border-bottom: 1px solid #373b41;
}

.code-language {
    color: #abb2bf;
    font-weight: 600;
    text-transform: uppercase;
}

/* Botón de copiar código */
.copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;
    opacity: 0.8;
}

.copy-button::before {
    content: '📄';
    font-size: 1rem;
}

.copy-button:hover {
    background-color: #0b5ed7;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.copy-button:active {
    transform: translateY(0);
    box-shadow: none;
}

.copy-button.copied {
    background-color: #28a745;
    animation: bounce 0.5s ease;
}

.copy-button.copied::before {
    content: '✅';
}

.copy-button.copied::after {
    content: 'Copied!';
    margin-left: 8px;
}

/* Animación para el botón de copiar */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

/* Estilos de código y sintaxis */
.code-block pre {
    margin: 0;
    padding: 16px;
    background: #282c34;
    overflow-x: auto;
    tab-size: 4;
}

.code-block code {
    font-family: 'Fira Code', 'JetBrains Mono', 'Consolas', monospace;
    font-size: 0.9em;
    line-height: 1.5;
    color: #abb2bf;
}

/* Tokens de sintaxis */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #8292a2;
    font-style: italic;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
    color: #f92672;
}

.token.boolean,
.token.number {
    color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #a6e22e;
}

/* Responsive */
@media (max-width: 768px) {
    .blog-post h1 {
        font-size: 1.5rem;
    }

    .blog-title {
        font-size: 2em;
    }

    .blog-subtitle {
        font-size: 1.5em;
    }

    .blog-section {
        font-size: 1.2em;
    }

    .blog-quote {
        padding: 0.8em 1.5em;
    }

    .code-block pre {
        padding: 12px;
    }
}

@media (max-width: 576px) {
    .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
    }

    .blog-quote {
        padding: 0.6em 1.2em;
    }
    
    .copy-button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}