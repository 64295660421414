/* src/css/contact.css */

.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.contact-container h2 {
  text-align: center;
  margin-bottom: 30px;
}

.contact-content {
  display: flex;
  justify-content: center;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
}

.contact-form button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #45a049;
}

.status-message {
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 15px;
  }
}